import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    VStack,
    Center,
    useToast,
    Progress,
    Icon,
    List,
    ListItem,
    ListIcon,
    HStack,
    CloseButton,
    Divider,
    Link
} from '@chakra-ui/react';
import { FiUploadCloud, FiCreditCard, FiFile } from 'react-icons/fi';


function generateRandomHex256Bit() {
    const array = new Uint8Array(32); // 32 bytes * 8 bits/byte = 256 bits
    window.crypto.getRandomValues(array);

    let hex = '';
    array.forEach(byte => {
        hex += byte.toString(16).padStart(2, '0');
    });

    return hex;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    // eslint-disable-next-line no-unused-vars
    const [product, setProduct] = useState({ id: 'prod_compare2files' });
    const [files, setFiles] = useState([]);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const toast = useToast();

    useEffect(() => {
        if (window.location.href === 'https://www.lawly.cz/success') {
            toast({
                title: "Email byl odeslán",
                status: "success",
                duration: 10000,
                isClosable: true,
            });
        }
    }, [toast]);

    useEffect(() => {
        if (window.location.href === 'https://www.lawly.cz/failed') {
            toast({
                title: "Nastala chyba, prosím kontaktujte nás na info@lawly.cz nebo na tel. čísle 735 876 542",
                status: "error",
                // duration: 5000,
                isClosable: true,
            });
        }
    }, [toast]);

    const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/rtf': ['.rtf'],
            'text/plain': ['.txt'],
            'application/vnd.oasis.opendocument.text': ['.odt'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/html': ['.html']
        },
        onDrop: acceptedFiles => {
            const validFiles = acceptedFiles.filter(file => file.size <= MAX_FILE_SIZE);
            if (validFiles.length !== acceptedFiles.length) {
                setError('Některé soubory byly příliš velké a nebyly přidány.');
                toast({
                    title: 'File size error.',
                    description: 'Některé soubory byly příliš velké a nebyly přidány.',
                    status: 'error',
                    duration: 10000,
                    isClosable: true,
                });
            } else {
                setError(null);
            }
            setFiles(prevFiles => [...prevFiles, ...validFiles]);
        }
    });



    const handleSubmit = async (event) => {
        event.preventDefault();
        const randomHex = generateRandomHex256Bit();
        console.log(randomHex);
        const uploadSuccess = await handleUpload(randomHex)

        if (!uploadSuccess) {
            return;
        }

        if (!stripe || !elements) {
            setError('Stripe.js hasn\'t yet loaded.');
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {
            const response = await axios.post('https://www.lawly.cz/api/create-checkout-session', {
                productId: product.id,
                email,
                randomHex,
                successUrl: 'https://www.lawly.cz/success',
                cancelUrl: 'https://www.lawly.cz/failed',
            });
            const { id } = response.data;
            await stripe.redirectToCheckout({ sessionId: id });
        } catch (error) {
            setError(error.message || 'An unknown error occurred.');
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = (fileName) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleUpload = async (randomHex) => {
        setLoading(true);
        const formData = new FormData();
        files.forEach(file => {
            formData.append('documents', file);
        });
        formData.append('email', email);
        formData.append('randomHex', randomHex);

        try {
            const response = await axios.post('/api/documents/upload', formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });
            console.log(response.data);
            setLoading(false);
            toast({
                title: 'Upload successful.',
                description: "Your files have been uploaded.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            return true
        } catch (error) {
            console.error('Error uploading files:', error);
            setLoading(false);
            toast({
                title: 'Upload error.',
                description: 'There was an issue uploading your files.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return false
        }
    };

    // const handlePayment = async (event) => {
    //     event.preventDefault();

    //     setLoading(true);
    //     // Call your backend to create the payment intent
    //     const { data } = await axios.post('https://www.lawly.cz/api/create-payment-intent', {
    //         email,
    //         productId: 'prod_compare2files',
    //     });

    //     const clientSecret = data.clientSecret;

    //     // Confirm the payment using the clientSecret
    //     const result = await stripe.confirmCardPayment(clientSecret, {
    //         payment_method: {
    //             card: elements.getElement(CardElement),
    //             billing_details: {
    //                 email,
    //             },
    //         },
    //     });

    //     if (result.error) {
    //         // Show error to your customer
    //         console.error(result.error.message);
    //     } else {
    //         // The payment has been processed!
    //         if (result.paymentIntent.status === 'succeeded') {
    //             console.log('Payment successful!');
    //         }
    //     }

    //     setLoading(false);
    // };

    return (
        <Center height="100vh" bg="gray.50">
            {/* <CardElement /> */}
            <Box bg="white" p={8} rounded="lg" shadow="lg" maxWidth="500px" width="full">
                <VStack spacing={4} align="center">
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        Analýza dokumentů
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" textAlign="left">
                        Porovnání dokumentů a analýza údajů pro zjištění shody nebo nesrovnalostí v identifikačních údajích, včetně údajů o osobách.
                    </Text>


                    {error && (

                        <Text color="red.500" fontSize="sm">
                            {error}
                        </Text>
                    )
                    }

                    <Box
                        {...getRootProps()}
                        borderWidth={2}
                        borderRadius="lg"
                        borderColor={isDragActive ? 'blue.500' : 'gray.300'}
                        p={6}
                        textAlign="center"
                        cursor="pointer"
                        bg={isDragActive ? 'blue.50' : 'gray.100'}
                        width="full"
                    >
                        <input {...getInputProps()} />
                        <Icon as={FiUploadCloud} boxSize={8} color="gray.500" />
                        <Text mt={2} color="gray.500">
                            {isDragActive ? "Drop the files here..." : "Přetáhněte své dokumenty zde nebo klikněte a vyberte soubory (.pdf, .doc, .docx, .rtf, .txt, .odt, .xls, .xlsx, .csv, .ppt, .pptx, .html)"}
                        </Text>
                        <Text mt={2} fontSize="sm" color="gray.400">
                            Maximální velikost souboru: 10MB
                        </Text>
                    </Box>



                    {files.length > 0 && (
                        <Box width="full">
                            <Text fontWeight="bold" mt={4} mb={2}>
                                Selected Files
                            </Text>
                            <List spacing={2}>
                                {files.map(file => (
                                    <ListItem key={file.name}>
                                        <HStack justify="space-between">
                                            <HStack>
                                                <ListIcon as={FiFile} color="blue.500" />
                                                <Text>{file.name}</Text>
                                            </HStack>
                                            <CloseButton onClick={() => handleRemoveFile(file.name)} />
                                        </HStack>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}

                    {uploadProgress > 0 && (
                        <Progress colorScheme="blue" size="sm" value={uploadProgress} width="full" />
                    )}

                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            placeholder="Vložte svůj email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>

                    {/* <Text fontSize="sm" color="red.600">
                        Aplikace je ve zkušební verzi, v případě chybné ana.
                    </Text> */}

                    <VStack spacing={3} width="full">
                        {/* <Button
                            colorScheme="blue"
                            width="full"
                            onClick={handleUpload}
                            isLoading={loading}
                            leftIcon={<FiUploadCloud />}
                            isDisabled={files.length === 0}
                        >
                            Upload Files
                        </Button> */}


                        <Button
                        
                            colorScheme="green"
                            width="full"
                            onClick={handleSubmit}
                            isLoading={loading}
                            leftIcon={<FiCreditCard />}
                            isDisabled={!email}
                        >
                            Zaplatit 50 Kč a analyzovat
                        </Button>
                        {/* <Button
                            colorScheme="red"
                            width="full"
                            onClick={handleSubmit}
                            isLoading={loading}
                            leftIcon={<FiCreditCard />}
                            isDisabled={!email}
                        >
                            Pay Stripe
                        </Button> */}
                    </VStack>

                </VStack>
                <VStack spacing={1.8} align="stretch" bg="gray.50" p={4} mt={4} borderRadius="md">
                    <Text fontSize="sm" color="gray.600">
                        Službu poskytuje:
                    </Text>
                    <Text fontSize="sm" fontWeight="medium">
                        Determinant s.r.o.
                    </Text>
                    <Text fontSize="sm">
                        Holečkova 836/69, Smíchov, 150 00 Praha 5
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                        Spisová značka: C 385077 vedená u Městského soudu v Praze
                    </Text>
                    <Divider my={2} />
                    <HStack spacing={4}>
                        <VStack align="start" spacing={1}>
                            <Text fontSize="xs" fontWeight="bold" color="gray.600">
                                Email:
                            </Text>
                            <Link href="mailto:info@lawly.cz" color="blue.500" fontSize="sm">
                                info@lawly.cz
                            </Link>
                        </VStack>
                        <VStack align="start" spacing={1}>
                            <Text fontSize="xs" fontWeight="bold" color="gray.600">
                                Tel:
                            </Text>
                            <Link href="tel:735876542" color="blue.500" fontSize="sm">
                                735 876 542
                            </Link>
                        </VStack>
                    </HStack>
                </VStack>
            </Box>
        </Center>
    );
}

const StripeCheckout = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default StripeCheckout;
