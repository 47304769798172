import { ChakraProvider } from '@chakra-ui/react';
import StripeCheckout from './components/Page';

function App() {
  return (
    <ChakraProvider>
      <StripeCheckout />
    </ChakraProvider>
  );
}

export default App;
